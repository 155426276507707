html {
    width: 100%;
}
body {
    margin: 0;
}
#root {
    font-family: "Roboto", sans-serif;
}
.MuiDataGrid-columnSeparator {
    display: none !important;
}
a {
    text-decoration: none;
}
a:focus, a:hover, a:visited, a:link, a:active {
    text-decoration: none;
}
